<template>
  <div>
    <!-- <hq-navigation-bar
      v-if="isLoggedIn"
      :userName="userName"
      :userInitials="initials"
      @user-logout="logout"
    ></hq-navigation-bar> -->
    <section>
      <side-navigation
        v-if="isLoggedIn"
        :userName="userName"
        :userInitials="initials"
        @user-logout="logout"
      >
        <slot />
      </side-navigation>
    </section>
  </div>
</template>

<script>
import SideNavigation from "@/components/ui/HQ/sideNavHQ.vue";
// import HqNavigationBar from "@/components/ui/HQ/HQnavigationBar.vue";
export default {
  name: "hqContainer",
  components: {
    SideNavigation,
    // HqNavigationBar,
  },
  methods: {
    async logout() {
      const tokenPayload = localStorage.getItem("token");
      try {
        await this.$store.dispatch("auth/logout", tokenPayload);
        this.$router.push("/");
      } catch (err) {
        this.error = err.message || "Something went wrong, try later.";
      }
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    userName() {
      return this.$store.getters["auth/userName"];
    },
    initials() {
      return localStorage.getItem("initials");
    },
  },
};
</script>
